import React from 'react'
import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'
import Registration from './Registration'

function Register() {
  return (
    <div>
    <Header/>
    <Navbar/>
    <Registration/>
    <Footer/>
    </div>
  )
}

export default Register